<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
        <!---->
      </div>
      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-dw8">
        <!-- 水果呀 -->
        <div class="data">
          <ul>
            <li balls="1" itid="24300" code="Z1" id="Z1_24300">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b01">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24300" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24300" id="cash_24300" type="text"></ol>
            </li>
            <li balls="6" itid="24305" code="Z1" id="Z1_24305">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b06">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24305" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24305" id="cash_24305" type="text"></ol>
            </li>
            <li balls="11" itid="24310" code="Z1" id="Z1_24310">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b11">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24310" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24310" id="cash_24310" type="text"></ol>
            </li>
            <li balls="16" itid="24315" code="Z1" id="Z1_24315">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b16">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24315" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24315" id="cash_24315" type="text"></ol>
            </li>
          </ul>
          <ul>
            <li balls="2" itid="24301" code="Z1" id="Z1_24301">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b02">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24301" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24301" id="cash_24301" type="text"></ol>
            </li>
            <li balls="7" itid="24306" code="Z1" id="Z1_24306">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b07">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24306" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24306" id="cash_24306" type="text"></ol>
            </li>
            <li balls="12" itid="24311" code="Z1" id="Z1_24311">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b12">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24311" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24311" id="cash_24311" type="text"></ol>
            </li>
            <li balls="17" itid="24316" code="Z1" id="Z1_24316">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b17">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24316" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24316" id="cash_24316" type="text"></ol>
            </li>
          </ul>
          <ul>
            <li balls="3" itid="24302" code="Z1" id="Z1_24302">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b03">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24302" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24302" id="cash_24302" type="text"></ol>
            </li>
            <li balls="8" itid="24307" code="Z1" id="Z1_24307">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b08">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24307" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24307" id="cash_24307" type="text"></ol>
            </li>
            <li balls="13" itid="24312" code="Z1" id="Z1_24312">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b13">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24312" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24312" id="cash_24312" type="text"></ol>
            </li>
            <li balls="18" itid="24317" code="Z1" id="Z1_24317">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b18">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24317" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24317" id="cash_24317" type="text"></ol>
            </li>
          </ul>
          <ul>
            <li balls="4" itid="24303" code="Z1" id="Z1_24303">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b04">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24303" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24303" id="cash_24303" type="text"></ol>
            </li>
            <li balls="9" itid="24308" code="Z1" id="Z1_24308">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b09">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24308" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24308" id="cash_24308" type="text"></ol>
            </li>
            <li balls="14" itid="24313" code="Z1" id="Z1_24313">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b14">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24313" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24313" id="cash_24313" type="text"></ol>
            </li>
            <li balls="19" itid="24318" code="Z1" id="Z1_24318">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b19">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24318" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24318" id="cash_24318" type="text"></ol>
            </li>
          </ul>
          <ul>
            <li balls="5" itid="24304" code="Z1" id="Z1_24304">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b05">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24304" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24304" id="cash_24304" type="text"></ol>
            </li>
            <li balls="10" itid="24309" code="Z1" id="Z1_24309">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b10">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24309" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24309" id="cash_24309" type="text"></ol>
            </li>
            <li balls="15" itid="24314" code="Z1" id="Z1_24314">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b15">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24314" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24314" id="cash_24314" type="text"></ol>
            </li>
            <li balls="20" itid="24319" code="Z1" id="Z1_24319">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b20">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol id="rate_24319" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24319" id="cash_24319" type="text"></ol>
            </li>
          </ul>
        </div>
        <!-- -------- -->
        <!-- 第二个data -->
        <div class="data">
          <ul>
            <li balls="ds_d" itid="24460" code="Z1" id="Z1_24460">
              <ol class="td_name">单</ol>
              <ol id="rate_24460" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24460" id="cash_24460" type="text"></ol>
            </li>
            <li balls="ds_s" itid="24468" code="Z1" id="Z1_24468">
              <ol class="td_name">双</ol>
              <ol id="rate_24468" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24468" id="cash_24468" type="text"></ol>
            </li>
            <li balls="dx_d" itid="24476" code="Z1" id="Z1_24476">
              <ol class="td_name">大</ol>
              <ol id="rate_24476" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24476" id="cash_24476" type="text"></ol>
            </li>
            <li balls="dx_x" itid="24484" code="Z1" id="Z1_24484">
              <ol class="td_name">小</ol>
              <ol id="rate_24484" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24484" id="cash_24484" type="text"></ol>
            </li>
            <li balls="wsdx_d" itid="24508" code="Z1" id="Z1_24508">
              <ol class="td_name">尾大</ol>
              <ol id="rate_24508" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24508" id="cash_24508" type="text"></ol>
            </li>
            <li balls="wsdx_x" itid="24516" code="Z1" id="Z1_24516">
              <ol class="td_name">尾小</ol>
              <ol id="rate_24516" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24516" id="cash_24516" type="text"></ol>
            </li>
            <li balls="hds_d" itid="24524" code="Z1" id="Z1_24524">
              <ol class="td_name">合数单</ol>
              <ol id="rate_24524" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24524" id="cash_24524" type="text"></ol>
            </li>
            <li balls="hds_s" itid="24532" code="Z1" id="Z1_24532">
              <ol class="td_name">合数双</ol>
              <ol id="rate_24532" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24532" id="cash_24532" type="text"></ol>
            </li>
            <li balls="sj_s1" itid="24556" code="Z1" id="Z1_24556">
              <ol class="td_name">春</ol>
              <ol id="rate_24556" class="td_rate">3.7</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24556" id="cash_24556" type="text"></ol>
            </li>
            <li balls="sj_s2" itid="24564" code="Z1" id="Z1_24564">
              <ol class="td_name">夏</ol>
              <ol id="rate_24564" class="td_rate">3.7</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24564" id="cash_24564" type="text"></ol>
            </li>
            <li balls="sj_s3" itid="24572" code="Z1" id="Z1_24572">
              <ol class="td_name">秋</ol>
              <ol id="rate_24572" class="td_rate">3.7</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24572" id="cash_24572" type="text"></ol>
            </li>
            <li balls="sj_s4" itid="24580" code="Z1" id="Z1_24580">
              <ol class="td_name">冬</ol>
              <ol id="rate_24580" class="td_rate">3.7</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24580" id="cash_24580" type="text"></ol>
            </li>
            <li balls="fw_s1" itid="60474" code="Z1" id="Z1_60474">
              <ol class="td_name">东</ol>
              <ol id="rate_60474" class="td_rate">3.7</ol>
              <ol class="td_cash"><input once_max="0" name="cash_60474" id="cash_60474" type="text"></ol>
            </li>
            <li balls="fw_s2" itid="60482" code="Z1" id="Z1_60482">
              <ol class="td_name">南</ol>
              <ol id="rate_60482" class="td_rate">3.7</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_60482" id="cash_60482" type="text"></ol>
            </li>
            <li balls="fw_s3" itid="60490" code="Z1" id="Z1_60490">
              <ol class="td_name">西</ol>
              <ol id="rate_60490" class="td_rate">3.7</ol>
              <ol class="td_cash"><input once_max="0" name="cash_60490" id="cash_60490" type="text"></ol>
            </li>
            <li balls="fw_s4" itid="60498" code="Z1" id="Z1_60498">
              <ol class="td_name">北</ol>
              <ol id="rate_60498" class="td_rate">3.7</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_60498" id="cash_60498" type="text"></ol>
            </li>
            <li balls="zfb_z" itid="60506" code="Z1" id="Z1_60506">
              <ol class="td_name">中</ol>
              <ol id="rate_60506" class="td_rate">2.6</ol>
              <ol class="td_cash"><input once_max="0" name="cash_60506" id="cash_60506" type="text"></ol>
            </li>
            <li balls="zfb_f" itid="60514" code="Z1" id="Z1_60514">
              <ol class="td_name">发</ol>
              <ol id="rate_60514" class="td_rate">2.6</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_60514" id="cash_60514" type="text"></ol>
            </li>
            <li class="selected" balls="zfb_b" itid="60522" code="Z1" id="Z1_60522">
              <ol class="td_name">白</ol>
              <ol id="rate_60522" class="td_rate">2.7</ol>
              <ol class="td_cash"><input once_max="0" name="cash_60522" id="cash_60522" type="text"></ol>
            </li>
            <li>
              <ol class="td_empty" style="width:100%;height:17px;"></ol>
            </li>
          </ul>
        </div>
        <!-- ------- -->

      </div>
      <!-- 预设 -->
      <yushe :disabled="isDisabled" />
      <!--  -->
      <!-- 表格  -->

      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <td class="selected" data-code="1" name="Z1">第一球</td>
              <td data-code="2" name="Z2">第二球</td>
              <td data-code="3" name="Z3">第三球</td>
              <td data-code="4" name="Z4">第四球</td>
              <td data-code="5" name="Z5">第五球</td>
              <td data-code="6" name="Z6">第六球</td>
              <td data-code="7" name="Z7">第七球</td>
              <td data-code="8" name="Z8">第八球</td>
            </tr>
          </tbody>
        </table>
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="tableType==1?'selected':''" @click="setTableType(1)" data-code="ds" name="ds">单双</th>
              <th :class="tableType==2?'selected':''" @click="setTableType(2)" data-code="dx" name="dx">大小</th>
              <th :class="tableType==3?'selected':''" @click="setTableType(3)" data-code="sum" name="sum">总和</th>
              <th :class="tableType==4?'selected':''" @click="setTableType(4)" data-code="sum_ds" name="sum_ds">总和单双</th>
              <th :class="tableType==5?'selected':''" @click="setTableType(5)" data-code="sum_dx" name="sum_dx">总和大小</th>
              <th :class="tableType==6?'selected':''" @click="setTableType(6)" data-code="hds" name="hds">合单双</th>
              <th :class="tableType==7?'selected':''" @click="setTableType(7)" data-code="ws_dx" name="ws_dx">大小尾</th>
              <th :class="tableType==8?'selected':''" @click="setTableType(8)" data-code="sebo" name="sebo">色波</th>
              <th :class="tableType==9?'selected':''" @click="setTableType(9)" data-code="lh_b1-b8" name="lh_b1-b8">龙虎</th>
              <th :class="tableType==10?'selected':''" @click="setTableType(10)" data-code="siji" name="siji">四季</th>
            </tr>
          </tbody>
        </table>
        <!-- 单双 -->
        <table v-if="tableType==1" colspan="0" id="lt_ds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!-- 大小 -->
        <table v-if="tableType==2" colspan="0" id="lt_dx" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!-- 总和 -->
        <table v-if="tableType==3" colspan="0" id="lt_sum" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="x">66</td>
              <td class="x">79</td>
              <td class="d">93</td>
              <td class="x">75</td>
              <td class="d">104</td>
              <td class="x">75</td>
              <td class="d">86</td>
              <td class="d">95</td>
              <td class="d">93</td>
              <td class="x">73</td>
              <td class="x">64</td>
              <td class="x">66</td>
              <td class="x">71</td>
              <td class="x">80</td>
              <td class="d">85</td>
              <td class="d">88</td>
              <td class="d">86</td>
              <td class="d">104</td>
              <td class="d">86</td>
              <td class="x">77</td>
              <td class="x">65</td>
              <td class="x">57</td>
              <td class="x">71</td>
              <td class="d">99</td>
              <td class="x">77</td>
              <td class="x">63</td>
              <td class="x">77</td>
              <td class="d">100</td>
              <td class="x">78</td>
              <td class="x">81</td>
              <td class="d">92</td>
              <td class="d">87</td>
              <td class="x">74</td>
              <td class="d">89</td>
              <td class="x">78</td>
              <td class="x">69</td>
              <td class="d">86</td>
              <td class="x">81</td>
              <td class="x">75</td>
              <td class="d">100</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="d">100</td>
            </tr>

          </tbody>
        </table>
        <!-- 总和单双 -->
        <table v-if="tableType==4" colspan="0" id="lt_sum_ds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
            </tr>
            <tr>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
            </tr>

          </tbody>
        </table>
        <!-- 总和大小 -->
        <table v-if="tableType==5" colspan="0" id="lt_sum_dx" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_draw">和</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
            </tr>
            <tr>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!-- 合单双 -->
        <table v-if="tableType==6" colspan="0" id="lt_hds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
            </tr>

          </tbody>
        </table>
        <!-- 大小尾 -->
        <table v-if="tableType==7" colspan="0" id="lt_ws_dx" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
            </tr>
            <tr>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
            </tr>

          </tbody>
        </table>
        <!-- 色波 -->
        <table v-if="tableType==8" colspan="0" id="lt_sebo" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
            </tr>
            <tr>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
            </tr>

          </tbody>
        </table>
        <!-- 龙虎 -->
        <table v-if="tableType==9" colspan="0" id="lt_lh_b1-b8" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td>　</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_h">虎</td>
            </tr>

          </tbody>
        </table>
        <!-- 四季 -->
        <table v-if="tableType==10" colspan="0" id="lt_siji" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="siji_s2">夏</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
            </tr>
            <tr>
              <td class="siji_s2">夏</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s4">冬</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s2">夏</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td class="siji_s4">冬</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s4">冬</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!--  -->
      </div>

      <!-- table  end -->
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
    };
  },
  components: {
    yushe,
    topTimer,
  },
  watch: {
    amount(val) {
      this.rowsData.forEach((item) => {
        if (item.selected) {
          item.cash = val;
        }
      });
      this.$forceUpdate();
    },
  },
  computed: {
    //  预设按钮是否能点击 筛选数据传到下注弹框
    isDisabled() {
      let b = true;
      this.xiazhuList = [];
      this.rowsData.forEach((item) => {
        if (item.cash) {
          this.xiazhuList.push(item);
          b = false;
        }
      });
      if (!b) {
        this.$store.commit("setXZList", this.xiazhuList);
      } else {
        this.$store.commit("setXZList", []);
      }
      return b;
    },
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },
  methods: {
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    // input聚焦 判断显示快速下注
    showOrHideCheck(e, obj) {
      if (this.quick_b && this.quick_c) {
        this.rowsData.forEach((item) => {
          if (item.paiming == obj.paiming) {
            item.b = true;
          } else {
            item.b = false;
          }
        });
        this.$forceUpdate();
      }
    },
    // 选中快捷金额
    setCurrentName(item, num) {
      item.cash = num;
      item.b = false;
      console.log(item, num);
      this.$forceUpdate();
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    //   点击当前li
    setCurrentSelected(ele) {
      ele.selected = !ele.selected;
      ele.cash = ele.selected ? this.amount : "";
      this.$forceUpdate();
    },
  },
};
</script>
<style  scoped>
.listmain .data ul li {
  width: 25%;
}
.listmain .data {
  width: 100%;
}
</style>